import React from "react";
import Link from "next/link";

const VendorBar = () => (
    <section id="vendor-bar2" className="vendor-bar secondary-bg">
        <h3>
            <span>Ready For Fun?</span>
        </h3>

        <Link href="/tacboard-pricing">
            <a className="get-tac-now">Sign Up!</a>
        </Link>

        <div className="top-wave"></div>
    </section>
);

export default VendorBar;
