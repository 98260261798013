import styles from "./Layout.module.scss";
import React, { ReactNode } from "react";
import classNames from "classnames";

type Props = {
    children?: ReactNode;
    classes?: string[];
}

const Layout = (props: Props) => (
  <div className={classNames("layout", props.classes, styles["layout"])}>
    {props.children}
  </div>
)

export default Layout;
