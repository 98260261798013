import React, { ReactNode } from "react";

type Props = {
    noNav?: boolean;
}

const Footer = (props: Props) => {
    const d = new Date();
    const year = d.getFullYear();

    return (
<div>{!props.noNav && (<section id="footer-nav">
  <div className="footer-content-container">
    <a href="/" className="home">home</a>
    <a className="your-journey" href="your-journey">how it works</a>

    <a className="included" href="whats-included-with-tacboard">what's included</a>
    <a href="faqs" className="faqs">faqs</a>

    <a className="pricing" href="tacboard-pricing">pricing</a>
    <a className="pricing" href="/stories">stories</a>
    <div className="vline"></div>
    <a className="signupclicked" href="/dashboard/create-account">sign up</a>
  </div>
</section>)}
<section id="footer-links">
  <div className="footer-content-container">
    <div className="third">
      <a href="/">Home</a>
      <a className="your-journey" href="/your-journey">How It Works</a>
      <a href="/whats-included-with-tacboard">What's Included</a>
      <a href="/faqs">FAQs</a>
      <a href="/tacboard-pricing">Pricing</a>
      <a href="/dashboard">Log In</a>

      <a href="/dashboard/create-account">Sign Up</a>
    </div>
    <div className="third">
      <a href="/wedding-sign-shop" style={{backgroundColor:"#d9aa02", color:"white", fontWeight:"bold"}}>Free Sign Shop</a>
      <a href="/try-tacboard">Try TacBoard</a>
      <a href="http://tacb.io/LWtIJo-8st7E">View A Gallery</a>
      <a href="/live-display-setup">Live Photo Stream Setup</a>
      <a href="/testimonials">Testimonials</a>
      <a href="/contact-us">Contact Us</a>
      <a href="/stories">Stories</a>
    </div>
    <div className="third">
      <div className="badge-container" itemScope itemType="http://schema.org/Organization">
        <div className="badge-box" itemProp="starRating" itemScope itemType="http://schema.org/Rating">
          <a target="_blank" href="http://www.theknot.com/marketplace/TacBoard-Chicago-il-1023997?utm_source=vendor_website&amp;utm_medium=banner&amp;utm_term=1e3b4a75-b8a3-49b4-8217-a6f300c879c8&amp;utm_campaign=vendor_badge_assets"><img src="https://media-api.xogrp.com/images/456d566a-a6ca-475f-8f5a-93e45cc7e81d" width="85" alt="Couples love us! See our reviews on The Knot. The Knot Reviews" /></a>

          <meta itemProp="name" content="TacBoard" />
          <meta itemProp="description" content="Engage your guests with Live Display and don't miss a moment from your wedding day. TacBoard is the easiest way to collect your wedding guests' photos while adding an extra splash of fun to your reception. No app. No wedding hashtag hassle. Just text message. Guest simply text the photos they are taking to a unique phone number and those photos feed instantly into your own private wedding gallery." />
          <div className="stars">
            <meta itemProp="ratingValue" content="5" />⭐⭐⭐⭐⭐
          </div>
        </div>
        <div className="badge-box" itemProp="starRating" itemScope itemType="http://schema.org/Rating">
          <a target="_blank" href="https://goo.gl/jPkvrT"><img src="https://tacboard.com/img/google-my-business.png" width="85" alt="Google Business Reviews" /></a>
          <meta itemProp="name" content="TacBoard" />
          <meta itemProp="description" content="Engage your guests with Live Display and don't miss a moment from your wedding day. TacBoard is the easiest way to collect your wedding guests' photos while adding an extra splash of fun to your reception. No app. No wedding hashtag hassle. Just text message. Guest simply text the photos they are taking to a unique phone number and those photos feed instantly into your own private wedding gallery." />
          <div className="stars">
            <meta itemProp="ratingValue" content="5" />⭐⭐⭐⭐⭐
          </div>
        </div>
        <div className="badge-box">
          <img src="https://tacboard.com/img/veteran_owned_icon.png" width="75" alt="Veteran-Owned-Business-VOSB" />
          <div> </div>
        </div>
      </div>

      <a href="mailto:hello@tacboard.com" className="footer-email-link">hello@tacboard.com</a>
      <p>1509 W Berwyn Ave, Chicago, IL 60640</p>
    </div>
  </div>
</section>

<section id="footer-legal">
  <a href="/privacy-policy">Privacy Policy</a>
  <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
  <a href="/terms-of-service">Terms of Service</a>
  <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
  <a>TacBoard © <span id="copyYear">{year}</span></a>
</section>
</div>
);
}

export default Footer;
