import * as React from "react";

const Header: React.FC = ({ children }) => (
    <header>
        <input type="checkbox" name="mobNav" id="mobNav" defaultChecked={false} />
        <label htmlFor="mobNav">
            <span></span>
            <span></span>
            <span></span>
        </label>

        <ul className="nav">
            <ul className="navigation">
                <li><a className="venue-overview" href="/">home</a></li>
                <li><a className="your-journey" href="/your-journey">how it works</a></li>
                <li className="ddli"><a className="dropdown"><span className="nomo">events</span>
                    <div className="dropdown-content">
                    <a className="dd-link" href="/tacboard-weddings" >
                        weddings
                    </a>
                    <a className="dd-link" href="/tacboard-corporate-events" >
                        corporate events
                    </a>
                    <a className="dd-link" href="/tacboard-reunions" >
                        reunions
                    </a>
                    <a className="dd-link" href="/tacboard-parties" >
                        parties
                    </a>
                    <a className="dd-link" href="/tacboard-fundraisers" >
                        fundraisers
                    </a>
                    <a className="dd-link" href="/tacboard-mitzvahs" >
                        mitzvahs
                    </a>
                    </div>
                    </a>
                </li>
                <li>
                    <a className="included" href="/whats-included-with-tacboard" >
                        includes
                    </a>
                </li>
                <li><a className="faqs" href="/faqs">faqs</a></li>
                <li><a className="pricing" href="/tacboard-pricing">pricing</a></li>
                {/* <li><a className="" href="/stories">stories</a></li> */}
            </ul>

            <ul className="connect">
                <li className="login log-in vend"><a href="/dashboard">log in</a></li>
                <li className="login signup vend">
                    <a className="get-started" href="/tacboard-pricing">sign up</a>
                </li>
            </ul>
        </ul>

        <div className="brand">
            <img src="https://tacboard.com/img/Logo2.svg" />
        </div> 
    </header>
)

export default Header;
